
import { defineComponent } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "house-building-policy-information-generators-component",
  components: {},
  props: {
    generators: Array
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
